import { LuTrash2 } from "react-icons/lu";
import { FaPlus } from "react-icons/fa";
import { LuTable2 } from "react-icons/lu";
import { IoCreateOutline } from "react-icons/io5";
import { HiOutlineSave, HiOutlineUpload } from "react-icons/hi";
import { AiOutlineAppstore } from "react-icons/ai";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { TbLogin2, TbLogout2 } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import { FaHeart, FaRegHeart, FaGithub } from "react-icons/fa";
import { LuLock } from "react-icons/lu";
import { MdOutlinePublic } from "react-icons/md";
import { VscArrowBoth } from "react-icons/vsc";
import { LuCircuitBoard } from "react-icons/lu";
import { FaCodeFork } from "react-icons/fa6";
import { BiEditAlt } from "react-icons/bi";
import { MdOutlineStraight } from "react-icons/md";
import { BsFiletypePng } from "react-icons/bs";
import { LuPackageOpen } from "react-icons/lu";

export const TrashIcon = LuTrash2;
export const AddIcon = FaPlus;
export const TableIcon = LuTable2;
export const CreateIcon = IoCreateOutline;
export const SaveIcon = HiOutlineSave;
export const UploadIcon = HiOutlineUpload;
export const MenuIcon = AiOutlineAppstore;
export const GridIcon = BsFillGrid3X3GapFill;
export const LogInIcon = TbLogin2;
export const LogOutIcon = TbLogout2;
export const ProfileIcon = CgProfile;
export const FilledLikeIcon = FaHeart;
export const EmptyLikeIcon = FaRegHeart;
export const PrivateIcon = LuLock;
export const PublicIcon = MdOutlinePublic;
export const ExpandIcon = VscArrowBoth;
export const CircuitIcon = LuCircuitBoard;
export const ForkIcon = FaCodeFork;
export const EditIcon = BiEditAlt;
export const GithubIcon = FaGithub;
export const StraightLinesIcon = MdOutlineStraight;
export const PNGIcon = BsFiletypePng;
export const CommonsIcon = LuPackageOpen;
